<template>
  <div class="home">
    <b-button variant="outline-primary" class="m-1" @click="closeModal">
      CANCELAR
    </b-button>
    <b-button variant="primary" class="m-1" @click="updateData">
      SALVAR
    </b-button>
  </div>
</template>

<script>
export default {
  name: "ButtonsEdit",

    methods:{
    closeModal() {
      this.$router.go(-1);
      this.$emit("cancel");
    },
     updateData() {
      this.$emit("update");
    }
  }
};
</script>
<style scoped>
.home{
  margin-top:auto;
  margin-bottom:auto;
  text-align:right;
}
</style>