<template>
  <div class="home">
    <Header />
    <b-container>
      <div @click="$router.go(-1)">
        <ButtonReturn 
          v-bind:document="userData.document"
          v-bind:title="title"/>  
      </div>
      <div>
        <FormDocument 
          v-bind:perfil="modeProfile"
          v-bind:userData="userData"
          @observationEmit="getObservation($event)" 
          >
          <template v-slot:form-footer-button>
            <ButtonsEdit
              @update="callModal"
            />
          </template>
        </FormDocument>
      </div>
    </b-container>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import FormDocument from "@/components/FormDocument.vue";
import ButtonsEdit from "@/components/ButtonsEdit.vue";
import ButtonReturn from "@/components/ButtonReturn.vue"

export default {
  name: "EditDocument",
  components: {
    Header,
    FormDocument,
    ButtonsEdit,
    ButtonReturn
  },
  props: {},
  async created () {
    this.$root.name = sessionStorage.name;
  },
  data() {
    return {
      document: this.$route.params.document,
      modeProfile: this.$route.params.modeProfile,
      userData: this.$root.rootObject,
      title: "EDITANDO",
    };
  },
  mounted() {
    this.$root.pessoaId = sessionStorage.pessoaId;
  },
  methods: {
    getObservation(value){
      this.userData.observation = value;
    },
    callModal() {
        this.$bvModal
        .msgBoxConfirm("Essa ação não poderá ser desfeita", {
          title: "Tem certeza que deseja salvar as alterações ?",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "SIM",
          cancelTitle: "NÂO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.update();
          }
        })
        .catch((err) => {
          this.$root.$emit('notify', { label: err, time: 10, type: 'danger'});
        });
    },
    update() {
      this.showAlert=false;

      const body = {
        document: this.userData.document,
        documentType: this.userData.document.length === 14 ? 'CNPJ' : 'CPF',
        user: sessionStorage.name,
        observation: this.userData.observation,
      };

      this.axios
        .post("/restrictivelist/update", body)
        .then((response) => {
          var result = response.data;
          if(result.status){
            this.$root.$emit('notify', { label: 'Os dados foram atualizados com sucesso', time: 5, type: 'success'});
            this.$router.push("/list");
          } else {
              this.$root.$emit('notify', { label: 'Não foi possível atualizar os dados', time: 5, type: 'danger'});
              this.$router.push("/list");
          }
        })
        .catch((error) => {
          this.$root.$emit('notify', { label: error, time: 10, type: 'danger'});
        });
    },
  },
};
</script>
